.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 1000px;
    height: 600px;
    border-radius: 10px;
  }
  
  .aboutSeactionContent{
    width: 55%;
  }

  .bMSeaction{
    width: 55%;
  }

  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  .section2{
    display: flex;
    justify-content: space-around;
  }

  .contactSection{
    display: flex;
    justify-content: center;
    padding: 0px 150px;
    align-items: center;

  }

  .aboutSeactionContentTitle{
    font-size: 50px;
    font-weight: 700;
    font-family: Roboto, sans-serif;

  }

  .contactSection1{
    width: 55%;
  }
  .contactSection2{
    width: 500px;
  
  }

  .aboutSeaction{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .section2ImageGrid{
    width: 500px;
  }

  .aboutSeactionGrid{
    width: 500px;
  }
  
  .heroSectionHeading{
    font-size: 70px;
    font-weight: 700;
    width: 75%;
    margin-bottom: 0px;
  }

  .heroSection{
    position: absolute;
    top: 30%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .footerSection{
    background-color: rgb(25, 27, 29);
    height: 350px;
  }


  .footerSectionTitle{
    font-size: 25px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  .footerSectionTC{
    flex: 3;
    padding-top: 50px;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .section2{
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    .footerSectionTC{
      flex: 3;
      padding-top: 0px;
    }

    .footerSection{
      background-color: rgb(25, 27, 29);
      height: 600px;
      padding: 25px;
    }

    .aboutSeactionContentTitle{
      font-size: 25px;
      font-weight: 700;
      font-family: Roboto, sans-serif;
  
    }

    .footerSectionQuickLinks{
      display: none;
    }

    .aboutSeactionContent{
      width: 85%;
    }

    .footerSectionTitle{
      font-size: 20px;
      font-weight: 500;
      font-family: Roboto, sans-serif;
    }

    .footerSectionParagraph{
      font-size: 14px;
    }

    .heroSection{
      position: absolute;
      top: 26%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .heroSectionHeading{
      font-size: 40px;
      font-weight: 700;
      width: 75%;
      margin-bottom: 0px;
    }

    .aboutSeactionGrid{
      display: none;
      width: 100%;
    }
  

    .section2ImageGrid{
      width: 100%;
    }

    .aboutSeaction{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .contactSection1{
      width: 0%;
    
    }
    .contactSection2{
      width: 100%;
    }
    .contactSection{
      display: flex;
      justify-content: center;
      padding: 0px;
      align-items: flex-start;
      flex-direction: column;
    
    }

    .bMSeaction{
      width: 85%;
      margin: auto;
    }
  }
  