.dash{
    background-color:#1976d2 ;
    padding: 1.5px;
    width: 90%;
    margin: auto;
    display: none;

}
.navbarName:hover + .dash{
    display: block;
}

.navToolbar{
    display: flex;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .navToolbar{
        display: none;
    }
  }